<template>
  <div v-if="details.specials && details.specials.length > 0" class="scre-wrap">
    <!-- 建筑总体得分 -->
    <template>
      <div
        class="flex items-center mt-0.5 px-2.5 py-4 bg-white"
        @click="selDetail(1000)"
      >
        <div class="font-bold flex-1 text-sm">
          {{ details.specials[0].specialName }}总分
        </div>
        <div v-if="!details.totalScore" class="pr-1 text-xs text-gray-300">
          完成评价自动统计总分
        </div>
        <div v-if="details.totalScore" class="pr-1">
          <span class="score-text" style="color: red; font-size: 1.2rem">{{
            details.totalScore
          }}</span>
          <span class="text-xs ml-1">分</span>
        </div>
        <van-icon
          :name="active == 1000 ? 'arrow-up' : 'arrow-down'"
          size="14"
          color="#aaa"
        />
      </div>
      <!-- 详细评分 -->
      <div
        v-show="active == 1000"
        class="my-1.5 px-2.5 py-3.5 bg-white leading-none"
      >
        <div class="mb-10">
          <div class="content-title text-sm">项目总览图片</div>
          <div class="flex flex-wrap mt-3.5">
            <com-upload
              key="overview"
              :readonly="true"
              :file-list.sync="details.imgUrl"
            />
          </div>
        </div>
      </div>
    </template>
    <!-- 空间动线得分 -->

    <template v-for="(item, index) in details.list">
      <div :key="index" class="">
        <div
          class="flex items-center mt-0.5 px-2.5 py-4 bg-white"
          style="height: 57px"
          @click="selDetail(index)"
        >
          <div class="font-bold flex-1 text-sm">
            {{ numberList[index] }}、{{ item.specialName }}总分
          </div>
          <div
            v-if="!item.type0 || !item.type0.totalScore"
            class="pr-1 text-xs text-gray-300"
          >
            完成评价自动统计总分
          </div>
          <div v-if="item.type0 && item.type0.totalScore" class="pr-1">
            <span class="score-text">{{ item.type0.totalScore }}</span>
            <span class="text-xs ml-1">分</span>
          </div>
          <van-icon
            :name="active == index ? 'arrow-up' : 'arrow-down'"
            size="14"
            color="#aaa"
          />
        </div>
        <!-- 详细评分 -->
        <div
          v-show="active == index"
          class="my-1.5 px-2.5 py-3.5 bg-white leading-none"
        >
          <div class="mb-10">
            <div class="content-title text-sm">总体评价</div>
            <div
              class="flex mb-2 mt-3.5 px-2.5 py-3.5 text-sm rounded-md"
              v-html="item.type0.evaluation? item.type0.evaluation.replace(/(\r\n|\n|\r)/gm, '<br />'): '无'"
            />
            <div class="">
              <div class="content-title text-sm">分项评价图片</div>
              <div class="flex flex-wrap mt-3.5">
                <com-upload
                  key="overview"
                  :readonly="true"
                  :file-list.sync="item.type0.imgUrl"
                />
              </div>
            </div>
          </div>

          <div v-for="(im, ix) in item.children" :key="ix" class="">
            <Collapse v-model="activeNames" accordion>
              <CollapseItem :name="ix">
                <template #title>
                  <div
                    style="margin-top: 0"
                    class="mt-3.5 text-sm text-gray-600"
                  >
                    {{ ix + 1 }}、{{ im.specialName }}
                  </div>
                </template>
                <div
                  class="flex mb-2 mt-0.5 px-2.5 py-3.5 text-sm rounded-md"
                  v-html="
                    im.type0.evaluation
                      ? im.type0.evaluation.replace(/(\r\n|\n|\r)/gm, '<br />')
                      : '无'
                  "
                />
                <div class="mt-3 text-sm font-bold text-green-600">亮点</div>
                <div
                  class="flex mb-2 mt-3.5 px-2.5 py-3.5 text-sm rounded-md"
                  v-html="
                    im.type1.evaluation
                      ? im.type1.evaluation.replace(/(\r\n|\n|\r)/gm, '<br />')
                      : '无'
                  "
                />
                <!-- <div class="flex flex-wrap mt-4">
                    <com-upload
                      key="lightspot"
                      :readonly="true"
                      :file-list.sync="im.type1.imgUrl"
                    ></com-upload>
                  </div> -->

                <div class="mt-3 text-sm font-bold text-red-600">问题点</div>

                <div
                  class="flex mb-2 mt-3.5 px-2.5 py-3.5 text-sm rounded-md"
                  v-html="
                    im.type2.evaluation
                      ? im.type2.evaluation.replace(/(\r\n|\n|\r)/gm, '<br />')
                      : '无'
                  "
                />
                <!-- <div class="flex items-center py-3.5 border-b">
                    <input
                      class="flex-1 text-sm"
                      type="text"
                      v-model="im.type2.evaluation"
                      placeholder="请输入问题点内容"
                    />
                  </div> -->
                <!-- <div class="flex flex-wrap mt-4">
                    <com-upload
                      key="threat"
                      :readonly="true"
                      :file-list.sync="im.type2.imgUrl"
                    ></com-upload>
                  </div> -->
              </CollapseItem>
            </Collapse>
          </div>
        </div>
      </div>
    </template>
    <!-- 附加分项 -->
    <template>
      <div
        class="flex items-center mt-0.5 px-2.5 py-4 bg-white"
        style="height: 57px"
        @click="selDetail(1008)"
      >
        <div class="font-bold flex-1 text-sm">
          {{ numberList[details.list ? details.list.length : 0] }}、加分项
        </div>
        <div v-show="!details.addTotalScore" class="pr-1 text-xs text-gray-300">
          完成评价自动统计总分
        </div>
        <div v-show="details.addTotalScore" class="pr-1">
          <span class="score-text">{{ details.addTotalScore }}</span>
          <span class="text-xs ml-1">分</span>
        </div>
        <van-icon
          :name="active == 1008 ? 'arrow-up' : 'arrow-down'"
          size="14"
          color="#aaa"
        />
      </div>
      <div v-show="active == 1008" class="box">
        <template v-for="(item, index) in details.additionsList.type1">
          <div :key="index" class="">
            <div class="box-item flex items-center mt-1">
              <div
                v-show="!item.additionalName"
                class="txt"
                style="color: rgb(168 170 172)"
              >
                请先择加分项
              </div>
              <div v-show="item.additionalName" class="txt">
                {{ item.additionalName }}
              </div>
            </div>

            <div style="padding: 10px 10px">
              <div
                class="flex px-2.5 py-3.5 text-sm bg-gray-100 rounded-md"
                v-html="
                  item.evaluation
                    ? item.evaluation.replace(/(\r\n|\n|\r)/gm, '<br />')
                    : '无'
                "
              />
            </div>
            <div
              class="flex flex-wrap mt-3.5"
              style="padding-left: 10px; padding-right: 10px"
            >
              <com-upload
                key="overview"
                :readonly="true"
                :file-list.sync="item.imgUrl"
              />
            </div>
          </div>
        </template>
      </div>
    </template>
    <!-- 附加分项 -->
    <template>
      <div
        class="flex items-center mt-0.5 px-2.5 py-4 bg-white"
        style="height: 57px"
        @click="selDetail(1009)"
      >
        <div class="font-bold flex-1 text-sm">
          {{ numberList[details.list ? details.list.length + 1 : 0] }}、减分项
        </div>
        <div
          v-show="!details.reduceTotalScore"
          class="pr-1 text-xs text-gray-300"
        >
          完成评价自动统计总分
        </div>
        <div v-show="details.reduceTotalScore" class="pr-1">
          <span class="score-text">{{ details.reduceTotalScore }}</span>
          <span class="text-xs ml-1">分</span>
        </div>
        <van-icon
          :name="active == 1009 ? 'arrow-up' : 'arrow-down'"
          size="14"
          color="#aaa"
        />
      </div>
      <div v-show="active == 1009" class="box">
        <template v-for="(item, index) in details.additionsList.type2">
          <div :key="index" class="">
            <div class="box-item flex items-center mt-1">
              <div
                v-show="!item.additionalName"
                class="txt"
                style="color: rgb(168 170 172)"
              >
                请先择减分项
              </div>
              <div v-show="item.additionalName" class="txt">
                {{ item.additionalName }}
              </div>
            </div>

            <div style="padding: 10px 10px">
              <div
                class="flex px-2.5 py-3.5 text-sm bg-gray-100 rounded-md"
                v-html="
                  item.evaluation
                    ? item.evaluation.replace(/(\r\n|\n|\r)/gm, '<br />')
                    : '无'
                "
              />
            </div>
            <div
              class="flex flex-wrap mt-3.5"
              style="padding-left: 10px; padding-right: 10px"
            >
              <com-upload
                key="overview"
                :readonly="true"
                :file-list.sync="item.imgUrl"
              />
            </div>
          </div>
        </template>
      </div>
    </template>

    <picker
      ref="picker1"
      :columns="['临时样板房前厅', '架空层', '看房通道', '实体公区']"
      :obj="details.additionsList.type1[showPicker1Index]"
    />
    <picker
      ref="picker2"
      :columns="['技术规定', '技术标准', '正反面案例', '其他补充规定', '其他']"
      :obj="details.additionsList.type2[showPicker2Index]"
    />
  </div>
</template>
<script>
import { Icon, Uploader } from 'vant'
import comUpload from 'components/comUpload'
import picker from './picker'
import { Collapse, CollapseItem } from 'vant'
import { acceptance, acceptancePut } from '../api'
export default {
  naem:'ScoreEdit',
  components:{
    [Icon.name]:Icon,
    [Uploader.name]:Uploader,
    comUpload,
    Collapse,
    CollapseItem,
    picker
  },
  props:['details'],
  data() {
    return {
      active:null,
      // 概览图
      overview_img_list:[{ url:'https://img01.yzcdn.cn/vant/leaf.jpg' }],
      // 亮点图
      lightspot_img_list:[{ url:'https://img01.yzcdn.cn/vant/leaf.jpg' }],
      // 问题点图
      threat_img_list:[{ url:'https://img01.yzcdn.cn/vant/leaf.jpg' }],
      activeNames:[],
      showPicker1:false,
      showPicker1Index:0,
      showPicker2:false,
      showPicker2Index:0,
      numberList:{
        0:'一',
        1:'二',
        2:'三',
        3:'四',
        4:'五',
        5:'六',
        6:'七',
        7:'八',
        8:'九',
        9:'十',
        10:'十一',
        11:'十二',
        12:'十三',
        13:'十四',
        14:'十五',
        15:'十六',
        16:'十七',
        17:'十八',
        18:'十九',
        19:'二十',
        20:'二十一',
        21:'二十二',
        22:'二十三',
        23:'二十四',
        24:'二十五',
        25:'二十六',
        26:'二十七',
        27:'二十八',
        28:'二十九',
        29:'三十'
      }
    }
  },
  watch:{
    'details.list':{
      handler(val) {
        var totalScore = 0
        for (var item of val) {
          var score = 0
          for (var im of item.children) {
            score += im.type1.totalScore - im.type2.totalScore
          }
          item.type0.totalScore = Number(item.type0.initScore) + score
          totalScore += item.type0.totalScore
        }
        this.details.totalScore =
          totalScore +
          this.details.addTotalScore -
          this.details.reduceTotalScore
        this.$forceUpdate()
      },
      deep:true
    },
    'details.additionsList.type1':{
      handler(arr) {
        var totalScore = 0
        for (var item of arr) {
          totalScore += Number(item.totalScore)
        }
        this.details.addTotalScore = totalScore
        for (var detail_item of this.details.list) {
          var score = 0
          for (var im of detail_item.children) {
            score += im.type1.totalScore - im.type2.totalScore
          }
          detail_item.type0.totalScore = detail_item.type0.initScore + score
          totalScore += detail_item.type0.totalScore
        }
        this.details.totalScore = totalScore
      },
      deep:true
    },
    'details.additionsList.type2':{
      handler(arr) {
        console.log(arr)
        var totalScore = 0
        for (var item of arr) {
          totalScore += Number(item.totalScore)
        }
        this.details.reduceTotalScore = totalScore
        totalScore = -totalScore
        for (var detail_item of this.details.list) {
          var score = 0
          for (var im of detail_item.children) {
            score += im.type1.totalScore - im.type2.totalScore
          }
          detail_item.type0.totalScore = detail_item.type0.initScore + score
          totalScore += item.type0.totalScore
        }
        this.details.totalScore = totalScore
      },
      deep:true
    }
  },
  methods:{
    // 附加分新增
    type1AddClick() {
      this.details.additionsList.type1.push({
        additionalName:'',
        evaluation:'',
        imgUrl:'',
        initScore:0,
        isType:1,
        itemType:1,
        specialId:null,
        totalScore:null
      })
    },
    // 附加分删除
    type1DelClick(index) {
      this.details.additionsList.type1.splice(index, 1)
    },
    // 附加分项弹窗
    showPicker1Click(index) {
      this.$refs.picker1.showPicker = true
      this.showPicker1Index = index
    },
    // 附减分新增
    type2AddClick() {
      this.details.additionsList.type2.push({
        additionalName:'',
        evaluation:'',
        imgUrl:'',
        initScore:0,
        isType:2,
        itemType:1,
        specialId:null,
        totalScore:null
      })
    },
    // 附减分删除
    type2DelClick(index) {
      this.details.additionsList.type2.splice(index, 1)
    },
    // 附减分项弹窗
    showPicker2Click(index) {
      this.$refs.picker2.showPicker = true
      this.showPicker2Index = index
    },
    // 提交
    submit() {
      if (this.details.id) {
        this.acceptancePut()
      } else {
        this.acceptance()
      }
    },
    // 新增
    acceptance() {
      this.$toast.loading({
        message:'',
        forbidClick:true,
        duration:0
      })
      acceptance(this.processingData())
        .then((res) => {
          this.$toast.success('提交成功')
          this.$router.replace('/home')
        })
        .catch(() => {
          this.$toast.clear()
        })
    },
    // 修改
    acceptancePut() {
      this.$toast.loading({
        message:'',
        forbidClick:true,
        duration:0
      })
      acceptancePut(this.details.id, this.processingData())
        .then((res) => {
          this.$toast.success('修改成功')
          setTimeout(() => {
            this.$router.go(-1)
          }, 1500)
        })
        .catch(() => {
          this.$toast.clear()
        })
    },
    // 提交时候数据处理
    processingData() {
      var form = JSON.parse(localStorage.getItem('form'))
      var contents = []
      var details = JSON.parse(JSON.stringify(this.details))
      for (var item of details.list) {
        item.type0.imgUrl = item.type0.imgUrl ? item.type0.imgUrl.join() : ''
        contents.push(item.type0)
        for (var im of item.children) {
          im.type0.imgUrl = im.type0.imgUrl ? im.type0.imgUrl.join() : ''
          im.type1.imgUrl = im.type1.imgUrl ? im.type1.imgUrl.join() : ''
          im.type2.imgUrl = im.type2.imgUrl ? im.type2.imgUrl.join() : ''
          contents.push(im.type0)
          contents.push(im.type1)
          contents.push(im.type2)
        }
      }
      form.childs = contents
      form.childs = form.childs.concat(details.additionsList.type1)
      form.childs = form.childs.concat(details.additionsList.type2)
      form.id = details.id
      form.acceptanceDate = details.acceptanceDate
      form.totalScore = details.totalScore
      form.evaluation = details.evaluation
      form.imgUrl = details.imgUrl.join()
      form.state = 0
      return form
    },
    selDetail(index) {
      if (this.active == index) {
        this.active = -1
      } else {
        this.active = index
      }
    },
    Restricted1(values, item) {
      item.type0.initScore = this.plusOrMinus(values, item)
      this.$forceUpdate()
    },
    Restricted2(im, item) {
      im.type0.totalScore = this.plusOrMinus(im.type0.totalScore, item)
      this.$forceUpdate()
    },
    Restricted3(item) {
      item.totalScore = this.plusOrMinus1(item.totalScore)
    },
    plusOrMinus1(values) {
      let newValue
      values = String(values)
      if (!/[^0-9]/g.test(values)) {
        newValue = values
          .replace('.', '')
          .replace(/[^\-\d.]/g, '')
          .replace(/\-{2,}/g, '-')
          .replace(/\-{2,}/g, '-')
          .replace(/^\./g, '')
          .replace(/\.{2,}/g, '.')
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.')
        if (
          newValue.toString().indexOf('.') > 0 &&
          Number(newValue.toString().split('.')[1].length) > 1
        ) {
          newValue = parseInt(parseFloat(newValue) * 100) / 100
        }
        if (newValue.toString().split('-').length - 1 > 1) {
          newValue = parseFloat(newValue) || ''
        }
        if (
          newValue.toString().split('-').length > 1 &&
          newValue.toString().split('-')[0].length > 0
        ) {
          newValue = parseFloat(newValue) || ''
        }
        if (
          newValue.toString().length > 1 &&
          (newValue.toString().charAt(0) === '0' ||
            (newValue.toString().length > 2 &&
              newValue.toString().charAt(0) === '-' &&
              newValue.toString().charAt(1) === '0' &&
              newValue.toString().charAt(2) !== '.')) &&
          newValue.toString().indexOf('.') < 1
        ) {
          newValue = parseFloat(newValue) || ''
        }
        // // 判断整数位最多为9位
        // if (newValue.toString().indexOf('.') > 0 && Number(newValue.toString().split('.')[0].length) > 9) {
        //   newValue = `${newValue.toString().substring(0, 9)}.${newValue.toString().split('.')[1]}`;
        // } else if (newValue.toString().indexOf('.') < 0 && Number(newValue.toString().split('.')[0].length) > 9) {
        //   newValue = newValue.toString().substring(0, 9);
        // }
      } else {
        newValue = values.replace(/[^0-9]/g, '')
      }

      return newValue
    },
    plusOrMinus(values, item) {
      let newValue
      values = String(values)
      if (!/[^0-9]/g.test(values)) {
        newValue = values
          .replace('.', '')
          .replace(/[^\-\d.]/g, '')
          .replace(/\-{2,}/g, '-')
          .replace(/\-{2,}/g, '-')
          .replace(/^\./g, '')
          .replace(/\.{2,}/g, '.')
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.')
        if (
          newValue.toString().indexOf('.') > 0 &&
          Number(newValue.toString().split('.')[1].length) > 1
        ) {
          newValue = parseInt(parseFloat(newValue) * 100) / 100
        }
        if (newValue.toString().split('-').length - 1 > 1) {
          newValue = parseFloat(newValue) || ''
        }
        if (
          newValue.toString().split('-').length > 1 &&
          newValue.toString().split('-')[0].length > 0
        ) {
          newValue = parseFloat(newValue) || ''
        }
        if (
          newValue.toString().length > 1 &&
          (newValue.toString().charAt(0) === '0' ||
            (newValue.toString().length > 2 &&
              newValue.toString().charAt(0) === '-' &&
              newValue.toString().charAt(1) === '0' &&
              newValue.toString().charAt(2) !== '.')) &&
          newValue.toString().indexOf('.') < 1
        ) {
          newValue = parseFloat(newValue) || ''
        }
        // // 判断整数位最多为9位
        // if (newValue.toString().indexOf('.') > 0 && Number(newValue.toString().split('.')[0].length) > 9) {
        //   newValue = `${newValue.toString().substring(0, 9)}.${newValue.toString().split('.')[1]}`;
        // } else if (newValue.toString().indexOf('.') < 0 && Number(newValue.toString().split('.')[0].length) > 9) {
        //   newValue = newValue.toString().substring(0, 9);
        // }
      } else {
        newValue = values.replace(/[^0-9]/g, '')
      }
      var totalScore =
        item.type0.initScore == '-' ? 0 : Number(item.type0.initScore)
      for (var im of item.children) {
        totalScore +=
          im.type0.totalScore == '-' ? 0 : Number(im.type0.totalScore)
      }
      item.type0.totalScore = totalScore
      return newValue
    }
  }
}
</script>
<style scoped>
textarea {
  resize: none;
}
.submit-btn-box {
  position: fixed;
  width: 100vw;
  left: 0;
  bottom: 0px;
}
.submit-btn {
  width: calc(100vw - 20px);
  margin-left: 10px;
  line-height: 40px;
  margin-bottom: 20px;
  background: linear-gradient(to bottom, #7fa9ff, #6497ff);
  box-shadow: 2px 3px 11px 0px rgba(255, 255, 255, 0.26) inset,
    -1px -1px 9px 0px rgba(90, 140, 243, 0.67) inset;
  border-radius: 5px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  box-sizing: border-box;
  text-align: center;
  letter-spacing: 20px;
}
.box {
  width: calc(100% - 20px);
  background: #ffffff;
  border-radius: 5px;
  margin: 0 auto;
  overflow: hidden;
}
.box .box-item {
  width: 100%;
  height: 40px;
  justify-content: space-between;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.box .box-item .txt {
  margin-left: 10px;
  width: 340px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
.box-btn {
  width: calc(100% - 16px);
  height: 42px;
  margin-left: 8px;
  margin-top: 10px;
  border: 1px dashed #ff3e4c;
  border-radius: 5px;
  text-align: center;
  line-height: 42px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff3e4c;
}
</style>
